@import "../../variables.scss";

.dots {
  display: inline-flex;
  gap: 5px;

  > div {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: currentColor;
    animation: dotFlashing 0.75s infinite linear alternate;
    filter: opacity(0.5);
  }

  > :nth-child(1) {
    animation-delay: 0s;
  }
  > :nth-child(2) {
    animation-delay: 0.25s;
  }
  > :nth-child(3) {
    animation-delay: 0.5s;
  }
}

@keyframes dotFlashing {
  0% {
    filter: opacity(1);
  }
  50%,
  100% {
    filter: opacity(0.5);
  }
}
