@import "../../variables.scss";

details.dMenu {
  > summary {
    cursor: pointer;
    border-radius: 0.5rem;
  }

  > ul {
    z-index: 100;
  }

  &[open] > summary:before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: transparent;
    cursor: default;
  }
}

.container {
  color: $secondary-text;
  background-color: $secondary-background;
  box-shadow: 0 2px 2px black;
}
.navigation {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  max-width: 1240px;
  min-height: 4rem;
  margin: 0 auto;
  padding: 0 3vw;

  a {
    color: inherit;
    text-decoration: none;
    border-radius: 5px;

    &:focus-visible {
      @include focusable();
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 0 0.1rem;
  font-weight: 600;
  font-size: 1.2rem;

  > img {
    height: 3rem;
    width: 3rem;
  }
}

.menuContainer {
  flex-grow: 1;
  align-self: stretch;
}

.menuContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;

  > li > * {
    display: flex;
    padding: 0 1rem;
    height: 100%;
    align-items: center;
    min-height: 3rem;
    font-weight: 600;
    outline-offset: -2px;

    filter: brightness(70%);
    transition: filter 0.3s;

    &:hover,
    &.active {
      filter: brightness(100%);
    }
  }

  @media (max-width: $mobile-breakpoint) {
    &:not(.compact) {
      display: none;
    }
  }

  &.compact {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: calc(100% - 4rem);
    width: 55vw;
    top: 4rem;
    right: 0;
    background-color: darken($secondary-background, 2%);
    overflow-y: scroll;
    font-size: 1.1rem;
    visibility: hidden;
    z-index: 100;

    > li {
      min-height: 4em;

      > * {
        justify-content: right;
      }

      > details > summary {
        height: 100%;
        justify-content: right;

        &:before {
          display: none;
        }
      }
    }
  }
}

.loginButton {
  @include button-color($theme);
  border: none;
  padding: 1rem;
  line-height: 1;
  color: $secondary-background;
  border-radius: 0.5rem;
  flex-shrink: 0;
  font-weight: 600;
}

.userInfoContainer {
  position: relative;
}
.userInfo {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  height: 100%;

  > picture {
    width: 32px;
    height: 32px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: "";
  }

  &:focus-visible {
    @include focusable();
  }

  @media (max-width: $tablet-breakpoint) {
    > span {
      display: none;
    }
  }
}
.userInfoMenu {
  position: absolute;
  min-width: 150px;
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  right: 0;
  background-color: darken($secondary-background, 2%);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid $secondary-text;
  border-radius: 5px;
  text-align: right;

  > li {
    border-radius: inherit;

    > button,
    > a {
      color: inherit;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      width: 100%;
      height: 100%;
      text-align: inherit;
      line-height: inherit;
      padding: 0.5rem;
      border-radius: 5px;
      display: inline-block;
      box-sizing: border-box;

      &:focus-visible {
        @include focusable();
      }
    }

    .userInfoMenu {
      display: none;
      top: 0;
      right: calc(100% + 5px);
      margin: 0;

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        top: 0;
        bottom: 0;
        left: 100%;
      }
    }

    &:hover {
      background-color: $secondary-background;

      .userInfoMenu {
        display: block;
      }
    }
  }
}

.sideMenuButton {
  color: inherit;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 100%;
  margin-left: auto;
  cursor: pointer;
  border-radius: 5px;

  &:focus-visible {
    @include focusable();
  }
}

.sideMenu {
  visibility: hidden;
}
.sideMenuCloser {
  display: block;
  position: absolute;
  height: calc(100% - 4rem);
  width: 100vw;
  top: 4rem;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  border: none;

  &:focus-visible {
    @include focusable();
    outline-offset: -2px;
  }
}
.sideMenuEnter {
  visibility: visible;

  > .menuContent {
    visibility: visible;
    animation: slideIn 0.2s ease-out both;
  }
  > .sideMenuCloser {
    animation: fadeIn 0.2s ease-out both;
  }
}
.sideMenuEnterDone {
  visibility: visible;

  > .menuContent {
    visibility: visible;
  }
}
.sideMenuExit {
  visibility: visible;

  > .menuContent {
    visibility: visible;
    animation: slideOut 0.2s ease-out both;
  }
  > .sideMenuCloser {
    animation: fadeOut 0.2s ease-out both;
  }
}
.sideMenuExitDone {
  visibility: hidden;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
