@import "../variables.scss";
@import url("https://rsms.me/inter/inter.css");

body {
  margin: 0;
  line-height: 1.5;
  font-family: "Inter var", "Helvetica Neue", Arial, "ヒラギノ角ゴシック",
    "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $text;
  overflow-x: hidden;
  position: relative;
  accent-color: $theme;

  > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.contents {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  max-width: 1240px;
  align-self: center;
  padding: 0 3vw;
  width: 100%;
  box-sizing: border-box;

  > h1,
  > p {
    text-align: center;
  }

  > .errorBox {
    text-align: center;

    > pre {
      background-color: $secondary-background;
      color: $secondary-text;
    }

    > button {
      @include button-color($theme);
      border: none;
      border-radius: 0.5rem;
      padding: 1rem;
      color: $secondary-background;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .loadingBox {
    height: 100%;
    min-height: 128px;
    width: 100%;
    position: static;
  }
}

.loadingBox {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.delay {
  opacity: 0;
  animation: 0.3s fadeIn forwards;
  animation-delay: 0.5s;
}

.fadeIn {
  animation: 0.3s slideIn forwards;
}

.fadeOut {
  animation: 0.3s slideOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
