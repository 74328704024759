@import "../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-shrink: 0;
  background-color: $secondary-background;
  padding: 2rem 0;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.row {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  accent-color: $theme;

  > a {
    color: $theme;

    &:hover {
      color: lighten($theme, 10);
    }
  }
}

.pageLang {
  -webkit-appearance: menulist-button;
  height: 2rem;
  border: none;
  border-radius: 5px;
}
